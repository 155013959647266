<template>
  <div class="dress">
    <v-container v-if="sale">
      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Cliente</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          NOMBRE: {{ sale.client.first_name + " " + sale.client.last_name }}
        </v-col>
        <v-col cols="4">
          INDENTIFICACIÓN: {{ sale.client.colombian_id }}
        </v-col>
        <v-col cols="4"> DIRECCIÓN: {{ sale.client.address }} </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> TELÉFONO: {{ sale.client.phone }} </v-col>
        <v-col cols="4"> CORREO: {{ sale.client.email }} </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Venta</v-toolbar-title
            >
            <v-chip class="ma-2" color="secondary" text-color="white" small>
              {{ sale.reference }}
            </v-chip>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          VALOR DE VENTA: {{ sale.sale_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          VALOR INICIAL: {{ sale.initial_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          ASESOR: {{ sale.employee ? sale.employee.full_name : "N/A" }}
        </v-col>
        <v-col cols="2">
          <v-btn
            elevation="2"
            :href="sale.invoice_url"
            rounded
            dark
            color="blue"
            target="_blank"
          >
            Factura
            <v-icon right dark> mdi-cloud-print </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" v-if="sale.contract_url">
          <v-btn
            elevation="2"
            :href="sale.contract_url"
            rounded
            dark
            color="blue"
            target="_blank"
            >Contrato
            <v-icon right dark> mdi-file-document-multiple-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Vestidos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row v-for="(dress, index) in sale.dresses" :key="index">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-avatar color="secondary" size="25" class="white--text mr-3">{{
                index + 1
              }}</v-avatar>
              {{ dress.code }} - {{ dress.color }} -
              {{ dress.pivot.sale_value | formatNumber }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Pagos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersPayment"
            :items="sale.payments"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.value`]="{ item }">
              {{ item.value | formatNumber }}
            </template>
            <template v-slot:[`item.payment_method`]="{ item }">
              {{ getPaymentMethodsLan(item.payment_method) }}
            </template>
            <template v-slot:[`item.payment_type`]="item">
              {{ getPaymentTypeLan(item.item.payment_type) }}
            </template>
            <template v-slot:[`item.user`]="item">
              {{ item.item.user.name }}
            </template>
            <template v-slot:[`item.actions`]="item">
              <v-tooltip bottom v-if="item.item.payment_type !== 'INITIAL'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text :href="item.item.invoice_url" target="_bank">
                    <v-icon
                      color="blue"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="px-1"
                    >
                      mdi-cloud-print
                    </v-icon>
                  </v-btn>
                </template>
                <span>FACTURA</span>
              </v-tooltip>
              <span v-if="item.item.payment_type == 'INITIAL'">N/A</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog loader -->
    <v-dialog
      v-model="this.$store.state.sale.create.isLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_SALE } from "@/store/sale";
import { mapGetters } from "vuex";
export default {
  name: "Show",
  props: ["id"],
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    sale: null,
    headersPayment: [
      { text: "Valor", value: "value", sortable: false },
      { text: "Método de pago", value: "payment_method", sortable: false },
      { text: "Tipo de pago", value: "payment_type", sortable: false },
      { text: "Usuario", value: "user", sortable: false },
      { text: "Actiones", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.$store.dispatch("getSale", this.id);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_SALE) {
        this.sale = state.sale.sale;
      }
    });
  },
  computed: {
    ...mapGetters([
      "getPaymentMethodsLan",
      "getPaymentTypeLan",
      "getStatusColor",
      "getStatusLan",
    ]),
  },
  methods: {},
};
</script>
